interface SliderControlProps {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  style?: React.CSSProperties;
}

export const SliderControl: React.FC<SliderControlProps> = ({
  value,
  onChange,
  min,
  max,
  style,
}) => (
  <input
    type='range'
    min={min}
    max={max}
    value={value}
    onChange={onChange}
    style={{
      position: "absolute",
      height: "100%",
      width: "100%",
      opacity: 0,
      zIndex: 2,
      WebkitAppearance: "slider-vertical",
      writingMode: "vertical-lr",
      transform: "rotate(-180deg)",
      transformOrigin: "top left",
      outline: "none",
      background: "transparent",
      cursor: "pointer",
      ...style,
    }}
  />
);
