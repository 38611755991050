import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ROUTES } from "~/router";
import { BookIcon, DashboardIcon, LogoutIcon, ProfileIcon, tw } from "~/shared";
import { useLogoutMutation } from "~/shared/hooks/queries/useLogoutMutation";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarItem } from "./SidebarItem";

export const Sidebar = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { mutate: logout } = useLogoutMutation();

  const topMenuItems = [
    {
      name: t("sidebar.dashboard"),
      href: ROUTES.home,
      Icon: DashboardIcon,
    },
    {
      name: t("sidebar.courses"),
      href: `/course/1`,
      Icon: BookIcon,
    },
  ];

  const bottomMenuItems = [
    { name: t("sidebar.profile"), href: "#", Icon: ProfileIcon },
    { name: t("sidebar.logout"), href: "#", Icon: LogoutIcon, onClick: logout },
  ];

  return (
    <div
      className={tw(
        "fixed z-50 h-screen w-fit overflow-hidden border border-primary-100 bg-primary-50 px-5 py-10",
        isExpanded && "p-10",
      )}
    >
      <nav
        className={tw(
          "flex h-full w-9 flex-col items-center justify-center gap-8 transition-all duration-300",
          isExpanded && "w-48",
        )}
      >
        <SidebarHeader isExpanded={isExpanded} setIsExpanded={setIsExpanded} />

        <div className='flex h-full w-full flex-col items-center justify-between'>
          <ul className='w-full space-y-1'>
            {topMenuItems.map((item) => (
              <SidebarItem isExpanded={isExpanded} key={item.name} {...item} />
            ))}
          </ul>
          <ul className='w-full space-y-1'>
            {bottomMenuItems.map((item) => (
              <SidebarItem isExpanded={isExpanded} key={item.name} {...item} />
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};
