import { useEffect, useState } from "react";
import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import type { DragAndDropActivityResponse } from "~/api/getActivity";
import { useGetActivityQuery } from "~/shared/hooks/queries/useGetActivityQuery";

interface UseDragAndDropResult {
  draggablePositions: Record<string, UniqueIdentifier | null>;
  activeId: UniqueIdentifier | null;
  draggableIdsWithError: string[];
  dropzoneIdsWithError: string[];
  isActivityCorrect: boolean;
  isActivityIncorrect: boolean;
  handleDragStart: (event: DragStartEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  areAnswersCorrect: () => void;
  findDraggingOption: () => string;
  activityData: DragAndDropActivityResponse["data"] | undefined;
  resetDragAndDropState: () => void;
}

export const useDragAndDrop = (): UseDragAndDropResult => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [draggablePositions, setDraggablePositions] = useState<
    Record<string, UniqueIdentifier | null>
  >({});
  const [draggableIdsWithError, setDraggableIdsWithError] = useState<string[]>(
    [],
  );
  const [dropzoneIdsWithError, setDropzoneIdsWithError] = useState<string[]>(
    [],
  );
  const [isActivityCorrect, setIsActivityCorrect] = useState(false);
  const [isActivityIncorrect, setIsActivityIncorrect] = useState(false);

  const { activityId } = useParams<{ activityId: string }>();
  const { t } = useTranslation();
  const { data: activityData } = useGetActivityQuery(activityId ?? "");

  useEffect(() => {
    if (activityData) {
      const initialDraggablePositions = activityData.options.reduce(
        (acc: Record<string, null>, option: { id: number }) => {
          acc[option.id] = null;
          return acc;
        },
        {},
      );
      setDraggablePositions(initialDraggablePositions);
    }
  }, [activityData]);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(Number(event.active.id));
    setDraggableIdsWithError((prev) =>
      prev.filter((prevValue) => prevValue != event.active.id),
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    setDraggablePositions((prev) => {
      const previousElementInDropzone = Object.keys(prev).find(
        (draggableKey) => draggablePositions[draggableKey] === over?.id,
      );
      if (previousElementInDropzone) {
        return {
          ...prev,
          [active.id]: over?.id ?? null,
          [previousElementInDropzone]: null,
        };
      }

      return { ...prev, [active.id]: over?.id ?? null };
    });
    setActiveId(null);
  };

  const areAnswersCorrect = () => {
    const dropzoneErrorIds = new Set(
      activityData?.slots.map((slot) => `${slot.id}`),
    );
    Object.keys(draggablePositions).forEach((draggableId) => {
      const slotToCheck = activityData?.slots.find(
        (slot) => slot.id === draggablePositions[draggableId],
      );
      const isDraggablePositionCorrect = slotToCheck?.correctOptions.includes(
        Number(draggableId),
      );
      if (!isDraggablePositionCorrect) {
        setDraggableIdsWithError((prev) => {
          const newSet = new Set(prev);
          newSet.add(draggableId);
          return Array.from(newSet);
        });
      } else {
        setDraggableIdsWithError((prev) =>
          prev.filter((prevValue) => prevValue !== draggableId),
        );
        if (slotToCheck) {
          dropzoneErrorIds.delete(`${slotToCheck.id}`);
        }
      }
    });
    setDropzoneIdsWithError(Array.from(dropzoneErrorIds));
    if (dropzoneErrorIds.size === 0) {
      setIsActivityIncorrect(false);
      return setIsActivityCorrect(true);
    }
    setIsActivityCorrect(false);
    setIsActivityIncorrect(true);
  };

  const findDraggingOption = () => {
    const draggingOption = activityData?.options.find(
      (option) => option.id === activeId,
    );
    return draggingOption?.value ? t(draggingOption.value) : "";
  };

  const resetDragAndDropState = () => {
    setActiveId(null);
    setDraggablePositions({});
    setDraggableIdsWithError([]);
    setDropzoneIdsWithError([]);
    setIsActivityCorrect(false);
    setIsActivityIncorrect(false);
  };

  return {
    draggablePositions,
    activeId,
    draggableIdsWithError,
    dropzoneIdsWithError,
    isActivityCorrect,
    isActivityIncorrect,
    handleDragStart,
    handleDragEnd,
    areAnswersCorrect,
    findDraggingOption,
    activityData,
    resetDragAndDropState,
  };
};
