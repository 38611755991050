import { useState } from "react";
import type { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";

import type { DragAndDropActivityResponse } from "~/api/getActivity";
import { Button, EmptyPictureIcon, tw } from "~/shared";
import { DragDropTypes } from "~/types/dragAndDrop";
import { Draggable } from "./Draggable";
import { DropzoneSlot } from "./DropzoneSlot";
import { ImageModal } from "./ImageModal";

interface DragAndDropAreaPropsType {
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart: (event: DragEndEvent) => void;
  activityData: DragAndDropActivityResponse["data"];
  boxClassName: string;
  isActivityCorrect: boolean;
  findDraggingOption: () => string;
  draggableIdsWithError: string[];
  dropzoneIdsWithError: string[];
  activeId: UniqueIdentifier | null;
  draggablePositions: Record<string, UniqueIdentifier | null>;
  dropzonePositions: Record<string, string>;
  dragImage: JSX.Element;
  type: number;
  dragIcons?: Record<string, JSX.Element>;
}

export const DragAndDropArea = ({
  handleDragEnd,
  handleDragStart,
  activityData,
  boxClassName,
  isActivityCorrect,
  findDraggingOption,
  draggableIdsWithError,
  dropzoneIdsWithError,
  activeId,
  draggablePositions,
  dropzonePositions,
  dragImage,
  type,
  dragIcons,
}: DragAndDropAreaPropsType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const findDraggableLabelToRender = (draggableIdToRender: number) => {
    const draggableLabelToRender = activityData.options.find(
      (option) => option.id === draggableIdToRender,
    );
    return draggableLabelToRender ? t(draggableLabelToRender.value) : "";
  };

  return (
    <div>
      <ImageModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        {type === DragDropTypes.RIGHT_DRAGGABLES && (
          <Button
            variant='underline'
            className='flex w-1/4 gap-1 text-gray-500'
            onClick={() => setIsModalOpen(true)}
          >
            <EmptyPictureIcon />
            <p>{t("renalPhysiology.chapter3.module2.imageModalTitle")}</p>
          </Button>
        )}
        <div
          className={tw(
            "flex gap-20",
            type === DragDropTypes.RIGHT_DRAGGABLES && "flex-row-reverse",
          )}
        >
          <div className='flex w-1/3 flex-col justify-center gap-2'>
            {activityData?.options.map(
              (draggable) =>
                !draggablePositions[draggable.id] && (
                  <Draggable
                    key={draggable.id}
                    draggableId={draggable.id}
                    option={t(draggable.value)}
                    className={tw(boxClassName)}
                    dragType={type}
                    dragIcons={dragIcons}
                  />
                ),
            )}
          </div>
          <div className='relative w-fit'>
            {isActivityCorrect && <ConfettiExplosion />}
            {dragImage}
            {activityData?.slots.map((slot) => {
              return (
                <DropzoneSlot
                  key={slot.id}
                  slot={slot}
                  draggablePositions={draggablePositions}
                  dropzonePositions={dropzonePositions}
                  boxClassName={boxClassName}
                  findDraggableLabelToRender={findDraggableLabelToRender}
                  draggableIdsWithError={draggableIdsWithError}
                  type={type}
                  dragIcons={dragIcons}
                  dropzoneIdsWithError={dropzoneIdsWithError}
                />
              );
            })}
          </div>
        </div>
        <DragOverlay
          dropAnimation={{
            duration: 500,
            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
          }}
        >
          {activeId ? (
            <Draggable
              className={tw(boxClassName)}
              option={findDraggingOption()}
              draggableId={activeId}
              dragType={type}
              dragIcons={dragIcons}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};
