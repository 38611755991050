import type { SVGProps } from "~/constants";

export const ProfileIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M12 13.3334C12 12.2725 11.5786 11.2551 10.8284 10.5049C10.0783 9.7548 9.06087 9.33337 8 9.33337C6.93913 9.33337 5.92172 9.7548 5.17157 10.5049C4.42143 11.2551 4 12.2725 4 13.3334'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.00016 9.33333C9.47292 9.33333 10.6668 8.13943 10.6668 6.66667C10.6668 5.19391 9.47292 4 8.00016 4C6.5274 4 5.3335 5.19391 5.3335 6.66667C5.3335 8.13943 6.5274 9.33333 8.00016 9.33333Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
