import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ACTIVITY_TYPES } from "~/types";
import { ChoiceForm } from "./Choice";
import { DragAndDrop } from "./dragAndDrop/DragAndDrop";
import { SliderRenderer } from "./SliderRenderer";
import { Video } from "./video";

export const ActivityRenderer = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  switch (type) {
    case ACTIVITY_TYPES.choice:
      return <ChoiceForm />;
    case ACTIVITY_TYPES.dragAndDrop:
      return <DragAndDrop />;
    case ACTIVITY_TYPES.video:
      return <Video />;
    case ACTIVITY_TYPES.slider:
      return <SliderRenderer />;
    case ACTIVITY_TYPES.text:
      return <h1>{t("text")}</h1>;
    default:
      return <h1>{t("invalidType")}</h1>;
  }
};
