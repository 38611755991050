import { useTranslation } from "react-i18next";

import { ProgressBar } from "~/shared/components/utils/ProgressBar";
import type { Chapter } from "~/types";

interface ChapterCardHeaderProps {
  chapter: Chapter;
}

export const ChapterCardHeader = ({ chapter }: ChapterCardHeaderProps) => {
  const { t } = useTranslation();

  const { completedActivities, totalActivities } = chapter.progress;

  const chapterProgress = `${completedActivities}/${totalActivities} ${t("ui.activities")}`;
  const progress = Math.round((completedActivities / totalActivities) * 100);

  return (
    <div className='flex flex-row items-center justify-between'>
      <div className='flex flex-row items-center gap-2'>
        <h1 className='text-xl font-normal text-neutral-black'>
          {t("uppercaseChapter")} {chapter.order}
        </h1>
        <div className='size-1 rounded-full bg-[#ff8dbe]' />
        <p className='text-xl font-medium text-gray-600'>
          {t(`chapters.${chapter.title}`)}
        </p>
      </div>
      <div className='flex w-[40%] min-w-fit max-w-[25rem] flex-row items-center gap-6'>
        <ProgressBar
          progressClassName='rounded-full'
          className='rounded-full'
          progress={progress}
        />
        <p className='min-w-fit text-gray-600'>{chapterProgress}</p>
      </div>
    </div>
  );
};
