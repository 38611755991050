export interface Activity {
  id: string;
  type: ActivityTypes;
  description: string;
  order: number;
}

export interface Module {
  id: string;
  title: string;
  description: string;
  order: number;
  activities: Activity[];
}

export interface ChapterProgress {
  completedActivities: number;
  totalActivities: number;
}

export interface Chapter {
  id: string;
  title: string;
  description: string;
  order: number;
  progress: ChapterProgress;
  modules: Module[];
}

export interface CourseProgress {
  completedModules: number;
  totalModules: number;
}

export interface ModuleProgress {
  completedActivities: number;
  totalActivities: number;
}

export interface ModuleProgressData {
  id: string;
  progress: ModuleProgress;
}

export interface Course {
  id: string;
  title: string;
  description: string;
  progress: CourseProgress;
  chapters: Chapter[];
}

export interface CourseBasicData {
  id: string;
  title: string;
  description: string;
  progress: CourseProgress;
}

export const ACTIVITY_TYPES = {
  slider: "slider",
  dragAndDrop: "dragAndDrop",
  choice: "choice",
  text: "text",
  video: "video",
};

export type ActivityTypes = keyof typeof ACTIVITY_TYPES;

export const MODULE_DESCRIPTIONS = {
  "renalPhysiology.chapter1.module1.description":
    "renalPhysiology.chapter1.module1.description",
  "renalPhysiology.chapter1.module2.description":
    "renalPhysiology.chapter1.module2.description",
  "renalPhysiology.chapter2.module1.description":
    "renalPhysiology.chapter2.module1.description",
  "renalPhysiology.chapter2.module2.description":
    "renalPhysiology.chapter2.module2.description",
  "renalPhysiology.chapter3.module1.description":
    "renalPhysiology.chapter3.module1.description",
  "renalPhysiology.chapter3.module2.description":
    "renalPhysiology.chapter3.module2.description",
  "renalPhysiology.chapter3.module3.description":
    "renalPhysiology.chapter3.module3.description",
  "renalPhysiology.chapter3.module4.description":
    "renalPhysiology.chapter3.module4.description",
  "renalPhysiology.chapter3.module5.description":
    "renalPhysiology.chapter3.module5.description",
  "renalPhysiology.chapter3.module6.description":
    "renalPhysiology.chapter3.module6.description",
  "renalPhysiology.chapter3.module7.description":
    "renalPhysiology.chapter3.module7.description",
  "renalPhysiology.chapter3.module8.description":
    "renalPhysiology.chapter3.module8.description",
};
