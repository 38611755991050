import React from "react";
import { useTranslation } from "react-i18next";

import { DoctorHomePage } from "~/assets";

export const AboutUsSection = () => {
  const { t } = useTranslation();
  return (
    <div className='relative flex w-1/3 max-w-about-us flex-col overflow-hidden bg-neutral-white'>
      <div className='z-50 flex flex-col gap-8 p-10'>
        <h1 className='font-tobias text-3xl'>{t("about.title")}</h1>
        <p className='whitespace-pre-wrap text-gray-600'>
          {t("about.description")}
        </p>
      </div>
      <img className='absolute bottom-0' src={DoctorHomePage} alt='' />
    </div>
  );
};
