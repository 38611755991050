import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { answerActivity } from "~/api/courses/activities";

export const useAnswerActivity = () => {
  const queryClient = useQueryClient();
  const { moduleId } = useParams();

  const { mutate: answerActivityMutation, isPending } = useMutation({
    mutationFn: answerActivity,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getModuleProgress", moduleId],
      });
    },
  });

  return {
    answerActivityMutation,
    isPending,
  };
};
