import React from "react";
import { useTranslation } from "react-i18next";

import type { SliderType } from "~/api/getSliderActivity";
import { ClipboardListIcon, SaltIcon, WaterIcon } from "~/shared";
import OldPersonSVG from "~/shared/components/icons/OldPersonSVG";
import { Text } from "~/shared/components/ui";
import {
  calculateIconPosition,
  COMMON_SLIDER_STYLES,
  ICON_POSITION_BOTTOM,
  SALT_FILL_COLOR,
  SALT_SLIDER_POSITION,
  SLIDER_BASE_HEIGHT,
  SLIDER_MULTIPLIER,
  SliderControl,
  SliderIcon,
  WATER_FILL_COLOR,
  WATER_SLIDER_POSITION,
} from "..";
import { PERSON_SVG } from "./constants";

interface SliderContentProps {
  description: string;
  saltSlider: SliderType;
  waterSlider: SliderType;
  waterHeight: number;
  saltHeight: number;
  setWaterHeight: (value: number) => void;
  setSaltHeight: (value: number) => void;
  activityTitle: string;
}

export const SliderContent: React.FC<SliderContentProps> = ({
  description,
  saltSlider,
  waterSlider,
  waterHeight,
  saltHeight,
  setWaterHeight,
  setSaltHeight,
  activityTitle,
}) => {
  const { t } = useTranslation();

  const PersonSVGComponent = PERSON_SVG[activityTitle] || OldPersonSVG;
  return (
    <div className='flex flex-col gap-8'>
      <div className='flex w-10/12 flex-col gap-8 self-center rounded-lg bg-neutral-white p-4'>
        <div className='flex gap-10'>
          <div className='flex w-1/2 items-center justify-center'>
            <div className='flex flex-col gap-1 rounded-lg bg-primary-50 p-4'>
              <ClipboardListIcon className='h-5 w-5' />
              <Text>{t(description)}</Text>
            </div>
          </div>
          <div className='relative flex w-9/12 justify-center'>
            <SliderIcon
              IconComponent={WaterIcon}
              positionStyle={calculateIconPosition(waterHeight)}
              className='left-0'
            />
            <SliderControl
              value={waterHeight}
              onChange={(e) => setWaterHeight(Number(e.target.value))}
              min={Number(waterSlider.minimum)}
              max={Number(waterSlider.maximum)}
              style={{
                right: WATER_SLIDER_POSITION,
                bottom: ICON_POSITION_BOTTOM,
              }}
            />
            <SliderIcon
              IconComponent={SaltIcon}
              positionStyle={calculateIconPosition(saltHeight)}
              className='right-0'
            />
            <SliderControl
              value={saltHeight}
              onChange={(e) => setSaltHeight(Number(e.target.value))}
              min={Number(saltSlider.minimum)}
              max={Number(saltSlider.maximum)}
              style={{
                right: SALT_SLIDER_POSITION,
                bottom: ICON_POSITION_BOTTOM,
              }}
            />
            <div className='absolute top-[160px] z-50 flex w-10/12 justify-between border-t border-dashed border-gray-400'>
              <Text className='w-1/6 text-xs text-gray-400'>
                {t(saltSlider.title)}
              </Text>
              <Text className='text-xs text-gray-400'>
                {t(waterSlider.title)}
              </Text>
            </div>
            <div style={COMMON_SLIDER_STYLES}>
              <PersonSVGComponent
                className='h-full w-full'
                sliderHeight={
                  SLIDER_BASE_HEIGHT + waterHeight * SLIDER_MULTIPLIER
                }
                fillColor={WATER_FILL_COLOR}
              />
            </div>
            <div style={COMMON_SLIDER_STYLES}>
              <PersonSVGComponent
                className='h-full w-full'
                sliderHeight={
                  SLIDER_BASE_HEIGHT + saltHeight * SLIDER_MULTIPLIER
                }
                fillColor={SALT_FILL_COLOR}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
