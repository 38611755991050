import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ActivityLayout } from "~/layout/ActivityLayout";
import { useGetSliderActivityQuery } from "~/shared";
import Spinner from "~/shared/components/ui/Spinner/Spinner";
import { SliderContent } from "./SliderContent";
import { validateAnswer } from "./validateAnswer";

export const DoublePersonSlider: React.FC = () => {
  const [waterHeight, setWaterHeight] = useState(0);
  const [saltHeight, setSaltHeight] = useState(0);
  const [hasSuccessMessage, setHasSuccessMessage] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);

  const { activityId } = useParams<{ activityId: string }>();
  const { data: activityData, isPending } = useGetSliderActivityQuery(
    activityId ?? "",
  );

  useEffect(() => {
    const waterSlider = activityData?.sliders[0];
    const saltSlider = activityData?.sliders[1];

    if (waterSlider?.initial) setWaterHeight(Number(waterSlider.initial));
    if (saltSlider?.initial) setSaltHeight(Number(saltSlider.initial));
  }, [activityData]);

  const { t } = useTranslation();

  if (isPending) return <Spinner />;
  if (!activityData) return <p>No activity data.</p>;
  const { sliders, title, description, explanation } = activityData;
  const [saltSlider, waterSlider] = sliders;

  const resetActivityState = () => {
    setWaterHeight(0);
    setSaltHeight(0);
    setHasErrorMessage(false);
    setHasSuccessMessage(false);
  };

  const checkAnswer = () => {
    const isCorrect = validateAnswer({
      waterSlider,
      saltSlider,
      activityId,
      saltHeight,
      waterHeight,
    });
    setHasSuccessMessage(isCorrect);
    setHasErrorMessage(!isCorrect);
  };

  return (
    <ActivityLayout
      title={t(title)}
      successMessage={hasSuccessMessage ? t(explanation) : ""}
      hasError={hasErrorMessage}
      onCheckClick={checkAnswer}
      isCheckDisabled={false}
      hasAbsolutePositionExplanation
      resetActivityState={resetActivityState}
    >
      <SliderContent
        description={description}
        saltSlider={saltSlider}
        waterSlider={waterSlider}
        waterHeight={waterHeight}
        saltHeight={saltHeight}
        setWaterHeight={setWaterHeight}
        setSaltHeight={setSaltHeight}
        activityTitle={title}
      />
    </ActivityLayout>
  );
};
