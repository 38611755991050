import { useTranslation } from "react-i18next";

import { RoundedXIcon } from "../../icons";

export const IncorrectAnswerResultText = () => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-row items-center gap-2 rounded-xl border border-error-800 bg-error-50 p-4 text-error-800'>
      <RoundedXIcon />
      <span className='font-medium'>{t("ui.nextTime")}</span>
    </div>
  );
};
