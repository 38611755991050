import { useTranslation } from "react-i18next";

import { tw } from "~/shared";
import { CorrectAnswerResultText } from "~/shared/components/Activities/ui/CorrectAnswerResultText";
import { IncorrectAnswerResultText } from "~/shared/components/Activities/ui/IncorrectAnswerResultText";
import { NextAndPreviousButtons } from "~/shared/components/Activities/ui/NextAndPreviousButtons";

export const ActivityLayout = ({
  children,
  title,
  successMessage,
  hasError,
  onCheckClick,
  resetActivityState,
  containerClassName,
  isCheckDisabled = false,
  hasAbsolutePositionExplanation = false,
}: {
  children: JSX.Element;
  title: string;
  successMessage: string;
  hasError: boolean;
  onCheckClick: () => void;
  containerClassName?: string;
  isCheckDisabled?: boolean;
  hasAbsolutePositionExplanation?: boolean;
  resetActivityState?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex h-full flex-col justify-between gap-4 bg-neutral-background p-8'>
      <div className='flex h-full flex-col gap-4'>
        <h1 className='text-2xl font-semibold'>{t(title)}</h1>
        <div
          className={tw(
            "relative flex h-full flex-col justify-center gap-10 rounded-xl bg-white p-7",
            containerClassName,
          )}
        >
          <div className='flex flex-col items-center gap-4'>{children}</div>
          {(successMessage || hasError) && (
            <div
              className={tw(
                "flex w-full flex-col gap-4",
                hasAbsolutePositionExplanation && "absolute bottom-5 w-[95%]",
              )}
            >
              {successMessage && !hasError && (
                <CorrectAnswerResultText text={successMessage} />
              )}

              {hasError && <IncorrectAnswerResultText />}
            </div>
          )}
        </div>
      </div>
      <NextAndPreviousButtons
        onCheckClick={onCheckClick}
        isCheckDisabled={isCheckDisabled}
        resetActivityState={resetActivityState}
      />
    </div>
  );
};
