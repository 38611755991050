import { useTranslation } from "react-i18next";

import { getPasswordRules, tw } from "~/shared/utils";
import { CheckCircleIcon } from "../icons";
import { Text } from "../ui";

interface PasswordRequirementProps {
  label: string;
  isMet: boolean;
}
const PasswordRequirement = ({ label, isMet }: PasswordRequirementProps) => (
  <div className='flex items-center gap-2'>
    {isMet ? (
      <CheckCircleIcon className='h-4 w-4 text-green-600' />
    ) : (
      <div className={tw("h-4 w-4 rounded-full border-2 border-primary-500")} />
    )}
    <Text>{label}</Text>
  </div>
);

export const PasswordRequirements = ({ password }: { password: string }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-2 rounded-xl bg-gray-50 p-4'>
      <Text className='font-medium'>{t("Must have at least")}</Text>
      <ul className='gap-2'>
        {getPasswordRules(password).map((rule) => {
          return (
            <PasswordRequirement
              key={rule.label}
              label={rule.label}
              isMet={rule.isMet}
            />
          );
        })}
      </ul>
    </div>
  );
};
