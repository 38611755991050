import React from "react";
import { useOutlet } from "react-router-dom";

import { Sidebar } from "./Sidebar";

export const Layout = () => {
  const outlet = useOutlet();

  return (
    <div className='bg-neutral-background'>
      <Sidebar />

      <main className='pl-20'>
        <div className='-ml-0.5'>{outlet}</div>
      </main>
    </div>
  );
};
