import React from "react";
import { useTranslation } from "react-i18next";

import { AboutUsSection } from "~/shared/components/home/AboutUsSection";
import { CourseCard } from "~/shared/components/home/CourseCard";
import { useListCourses } from "~/shared/hooks/queries/courses/useListCourses";

export const Home = () => {
  const { t } = useTranslation();
  const { data: courses } = useListCourses();

  return (
    <div className='flex h-screen flex-col'>
      <div className='flex flex-col gap-8 bg-gray-50 px-12 py-10'>
        <h1 className='font-tobias text-4xl'>{t("welcomeMessage.title")}</h1>
        <p className='text-lg text-gray-600'>
          {t("welcomeMessage.description")}
        </p>
      </div>
      <div className='flex h-full flex-row justify-between bg-neutral-gray'>
        <div className='grid w-2/3 grid-cols-2 gap-4 px-12 py-7'>
          {courses?.map((course) => <CourseCard key={course.id} {...course} />)}
        </div>
        <AboutUsSection />
      </div>
    </div>
  );
};
