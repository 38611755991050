import type { SVGProps } from "~/constants";

export const RightValuesIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='18'
    height='91'
    viewBox='0 0 18 91'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M1 1L17 1L17 33L1 33'
      stroke='#8A96A6'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='4 4'
    />
    <path
      d='M1 37L17 37L17 90L1 90'
      stroke='#8A96A6'
      strokeWidth='0.8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='4 4'
    />
  </svg>
);
