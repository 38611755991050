export const DRAG_AND_DROP_ONE_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter1.module1.activity4.slot1.code":
    "bottom-0 right-[-28px]",
  "renalPhysiology.chapter1.module1.activity4.slot2.code": "bottom-1 left-5",
  "renalPhysiology.chapter1.module1.activity4.slot3.code": "left-5 top-0",
  "renalPhysiology.chapter1.module1.activity4.slot4.code":
    "right-[-28px] top-[55px] ",
};

export const DRAG_AND_DROP_TWO_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter1.module1.activity5.slot1.code":
    "top-[-5px] left-[-170px] ",
  "renalPhysiology.chapter1.module1.activity5.slot2.code":
    "bottom-[40px] left-[-170px]",

  "renalPhysiology.chapter1.module1.activity5.slot3.code":
    "top-[160px] right-[-267px]",

  "renalPhysiology.chapter1.module1.activity5.slot4.code":
    "top-[25px] right-[-135px]",

  "renalPhysiology.chapter1.module1.activity5.slot5.code":
    "bottom-[-48px] right-[-129px]",
  "renalPhysiology.chapter1.module1.activity5.slot6.code":
    "bottom-[95px] right-[-267px]",
};

export const DRAG_AND_DROP_THREE_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module1.activity5.slot1.code":
    "bottom-[75px] right-[26px]",
  "renalPhysiology.chapter3.module1.activity5.slot2.code":
    "top-[136px] right-[26px]",

  "renalPhysiology.chapter3.module1.activity5.slot3.code":
    "top-[82px] left-[-47px] ",

  "renalPhysiology.chapter3.module1.activity5.slot4.code":
    "top-[193px] left-[-47px]",

  "renalPhysiology.chapter3.module1.activity5.slot5.code":
    "top-[212px] right-[-93px]",
};

export const DRAG_AND_DROP_FOUR_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module2.activity6.slot1.code":
    "top-[-6px] left-[-7px] ",
};

export const DRAG_AND_DROP_FIVE_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module2.activity7.slot1.code":
    "top-[-6px] left-[-7px] ",
};

export const DRAG_AND_DROP_SIX_DROPZONE_POSITIONS = {
  "renalPhysiology.chapter3.module2.activity8.slot1.code":
    "top-[-6px] left-[-7px] ",
};
