import React from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { LightitLogo } from "~/assets";
import { ROUTES } from "~/router";
import { useUserStore } from "~/stores";

export const NotFound = () => {
  const navigate = useNavigate();

  const isLoggedIn = useUserStore((state) => Boolean(state.token));

  const handleNavigate = () => {
    navigate(generatePath(isLoggedIn ? ROUTES.home : ROUTES.login));
  };

  return (
    <main className='flex h-screen flex-col items-center justify-center'>
      <div className='mb-20'>
        <img src={LightitLogo} alt='logo' />
      </div>

      <p className='mb-10 text-2xl'>
        Looks like the page you are trying to access does not exist.
      </p>
      <button onClick={handleNavigate}>Go Back</button>
    </main>
  );
};
