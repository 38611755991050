import { useState } from "react";

import { tw } from "~/shared";
import type { Module } from "~/types";
import { ActivityCard } from "../activities/ActivityCard";
import { ExpandableCard } from "./ExpandableCard";
import { ModuleDescription } from "./ModuleDescription";

interface ModuleCardProps {
  chapterId: string;
  module: Module;
}

export const ModuleCard = ({ module, chapterId }: ModuleCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { description, activities } = module;

  const handleToggleExpanded = () => setIsExpanded((prev) => !prev);

  return (
    <div
      className={tw(
        "h-24 w-full gap-6 overflow-y-hidden text-left transition-all duration-500",
        isExpanded && "h-fit",
      )}
    >
      <ExpandableCard
        isExpanded={isExpanded}
        onToggleExpanded={handleToggleExpanded}
        {...module}
      />
      <div className='flex flex-col gap-6 rounded-b-xl bg-neutral-gray p-5'>
        <ModuleDescription description={description} />
        <div className='flex flex-col gap-2'>
          {activities.map((activity) => (
            <ActivityCard
              key={activity.id}
              activity={activity}
              chapterId={chapterId}
              moduleId={module.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
