import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { SliderType } from "~/api/getSliderActivity";
import { ActivityLayout } from "~/layout/ActivityLayout";
import { ClipboardListIcon, Text } from "~/shared";
import { checkAmountSliderIsCorrect } from "~/shared/utils/activities/checkAmountSliderIsCorrect";
import { BASE_VALUE } from "../constants";
import { AmountSliderCard } from "./AmountSliderCard";

const slidersMock = [
  {
    id: "5",
    title: "renalPhysiology.chapter3.module7.activity8.slider1.title",
    description:
      "renalPhysiology.chapter3.module7.activity8.slider1.description",
    minimum: "0.00",
    maximum: "100.00",
    initial: "50.00",
    answer_minimum: "60.00",
    answer_maximum: "100.00",
  },
  {
    id: "6",
    title: "renalPhysiology.chapter3.module7.activity8.slider2.title",
    description:
      "renalPhysiology.chapter3.module7.activity8.slider2.description",
    minimum: "0.00",
    maximum: "100.00",
    initial: "50.00",
    answer_minimum: "60.00",
    answer_maximum: "100.00",
  },
  {
    id: "7",
    title: "renalPhysiology.chapter3.module7.activity8.slider3.title",
    description:
      "renalPhysiology.chapter3.module7.activity8.slider3.description",
    minimum: "0.00",
    maximum: "100.00",
    initial: "50.00",
    answer_minimum: "60.00",
    answer_maximum: "100.00",
  },
  {
    id: "8",
    title: "renalPhysiology.chapter3.module7.activity8.slider4.title",
    description:
      "renalPhysiology.chapter3.module7.activity8.slider4.description",
    minimum: "0.00",
    maximum: "100.00",
    initial: "50.00",
    answer_minimum: "60.00",
    answer_maximum: "100.00",
  },
];

type SliderValueKeys =
  | "firstSlider"
  | "secondSlider"
  | "thirdSlider"
  | "fourthSlider";

export const AmountSlider: React.FC = () => {
  const [messages, setMessages] = useState({
    success: false,
    error: false,
  });

  const [sliderValues, setSliderValues] = useState({
    firstSlider: BASE_VALUE,
    secondSlider: BASE_VALUE,
    thirdSlider: BASE_VALUE,
    fourthSlider: BASE_VALUE,
  });

  const slidersData: { name: SliderValueKeys; slider: SliderType }[] = [
    { name: "firstSlider", slider: slidersMock[0] },
    { name: "secondSlider", slider: slidersMock[1] },
    { name: "thirdSlider", slider: slidersMock[2] },
    { name: "fourthSlider", slider: slidersMock[3] },
  ];

  const { t } = useTranslation();

  const validateAnswer = () => {
    const isCorrect = slidersData.every(({ name, slider }) =>
      checkAmountSliderIsCorrect(
        sliderValues[name],
        slider.answer_minimum,
        slider.answer_maximum,
      ),
    );

    setMessages({ success: isCorrect, error: !isCorrect });
  };

  const handleSliderChange = (name: SliderValueKeys, value: number) => {
    setSliderValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <ActivityLayout
      title={t(`Title`)}
      successMessage={messages.success ? t(`explanation`) : ""}
      hasError={messages.error}
      onCheckClick={validateAnswer}
      containerClassName='justify-start'
    >
      <div className='flex h-full w-4/5 max-w-[58rem] flex-col gap-12'>
        <div className='flex w-full flex-row items-center gap-2 overflow-hidden rounded-lg bg-primary-50 p-4'>
          <ClipboardListIcon className='size-5 min-w-fit' />
          <Text className='leading-6'>
            {
              "A 57-year-old patient with nausea and vomiting and has been unable to eat or drink for the past 48 hours. Blood pressure was 80/57 mmHg, heart rate was 125 bpm, and serum sodium levels were measured at 156 mEq/L."
            }
          </Text>
        </div>
        <div className='flex w-full flex-col gap-4'>
          {slidersData.map(({ name, slider }, index) => (
            <AmountSliderCard
              key={name}
              setValue={(value) => handleSliderChange(name, value)}
              slider={slider}
              value={sliderValues[name]}
              position={index}
            />
          ))}
        </div>
      </div>
    </ActivityLayout>
  );
};
