import { useQuery } from "@tanstack/react-query";

import { getSliderActivity } from "~/api/getSliderActivity";

export const useGetSliderActivityQuery = (activityId: string) => {
  const { data, isPending } = useQuery({
    queryKey: ["sliderActivity", activityId],
    queryFn: () => getSliderActivity(activityId),
    enabled: !!activityId,
  });

  return { data: data?.data, isPending };
};
