import React from "react";
import { useParams } from "react-router-dom";

import { ActivityRenderer } from "~/shared/components/Activities/ActivityRenderer";
import { Header } from "~/shared/components/Activities/ui/ActivityHeader/Header";
import { useGetCourseCached } from "~/shared/hooks/queries/courses/useGetCourseCached";
import { getActivityPath } from "~/shared/utils/activities/getActivityPath";

export const Activity = () => {
  const { courseId, chapterId, moduleId, activityId, type } = useParams();

  const {
    cachedCourseQuery: { data },
  } = useGetCourseCached(courseId);

  const { data: course } = { ...data };

  if (!course) return null;

  const activityPath = getActivityPath(
    course,
    chapterId,
    moduleId,
    activityId,
    type,
  );

  return (
    <div className='flex h-screen flex-col'>
      <Header activityPath={activityPath} course={course} />
      <ActivityRenderer />
    </div>
  );
};
