import { z } from "zod";

import { GRADUATION_YEARS } from "../constants/signUp";

const loginFormSchema = z.object({
  email: z.string().email("Please enter a valid email address."),
  password: z.string().min(1, "Password is required"),
});

const passwordSchema = z
  .string()
  .min(8, "Password is required and must be at least 8 characters.")
  .regex(/[a-z]/, "Password must contain at least 1 lowercase letter.")
  .regex(/[A-Z]/, "Password must contain at least 1 uppercase letter.")
  .regex(/\d/, "Password must contain at least 1 number.")
  .regex(
    /[^A-Za-z0-9]/,
    "Password must contain at least 1 special character (e.g., ! _ @ # ?).",
  );

const signUpFormSchema = z
  .object({
    gradYear: z.enum(GRADUATION_YEARS, {
      errorMap: () => ({ message: "Graduation year is required" }),
    }),
    password: passwordSchema,
    passwordConfirm: z.string(),
  })
  .superRefine(({ passwordConfirm, password }, context) => {
    if (password !== passwordConfirm) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Passwords don't match. Please make sure to enter the same password in both fields.",
        path: ["passwordConfirm"],
      });
    }
  });

export { loginFormSchema, signUpFormSchema };
