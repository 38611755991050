import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Spinner from "~/shared/components/ui/Spinner/Spinner.tsx";
import { useGetVideoActivity } from "~/shared/hooks/queries/activities/useGetVideo";
import { extractVideoId } from "~/shared/utils/activities/extractVideoId";
import { NextAndPreviousButtons } from "../ui/NextAndPreviousButtons";

export const Video = () => {
  const { t } = useTranslation();
  const { activityId: currentActivityId } = useParams();

  const { data, isLoading: isGetVideoLoading } =
    useGetVideoActivity(currentActivityId);

  const [isVideoRenderLoading, setIsVideoRenderLoading] = useState(true);

  if (!data) return null;

  const { resourceUrl, title } = data;

  const videoId = extractVideoId(resourceUrl);

  const isLoading = isGetVideoLoading || isVideoRenderLoading;

  return (
    <div className='flex h-full flex-col justify-between gap-4 bg-neutral-background p-8'>
      <div className='flex h-full flex-col gap-6 pb-12'>
        <h1 className='text-2xl font-semibold'>{t(`activities.${title}`)}</h1>
        <div className='relative w-auto overflow-hidden md:px-60 lg:px-64'>
          {isLoading && (
            <div className='absolute inset-0 flex items-center justify-center rounded-xl bg-gray-200 bg-opacity-50 md:mx-60 lg:mx-64'>
              <Spinner />
            </div>
          )}
          <div className='aspect-h-9 aspect-w-16 overflow-hidden rounded-xl'>
            {videoId ? (
              <iframe
                title='YouTube Video'
                className='h-full w-full'
                src={`https://www.youtube.com/embed/${videoId}`}
                onLoad={() => setIsVideoRenderLoading(false)}
                allowFullScreen
              />
            ) : (
              <p>{t("ui.videoNotFound")}</p>
            )}
          </div>
        </div>
      </div>
      <NextAndPreviousButtons />
    </div>
  );
};
