import { api } from "./axios";

export interface SignUpRequest {
  gradYear: string;
  password: string;
  inviteCode: string;
}
export interface AuthenticationResponse {
  access_token: string;
}

export interface LogInRequest {
  email: string;
  password: string;
}

export interface AuthenticationResponse {
  data: {
    token: string;
    expiresIn: number;
    tokenType: string;
  };
}

export const logout = async () => {
  const res = await api.post<null>(`/auth/logout`, {});
  return res.data;
};

export const login = async (body: LogInRequest) => {
  const { data } = await api.post<AuthenticationResponse>("/auth/login", {
    ...body,
  });
  return data;
};

export const signUp = async (body: SignUpRequest) => {
  const { data } = await api.post<AuthenticationResponse>("/auth/signup", {
    ...body,
  });
  return data;
};
