import type { SVGProps } from "~/constants";

export const VideoIcon = ({ className, ...props }: SVGProps) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <rect
      y='0.333328'
      width='21.3333'
      height='21.3333'
      rx='10.6667'
      fill='#FFF8CC'
    />
    <g clipPath='url(#clip0_1486_1744)'>
      <path
        d='M10.6664 14.9506C12.8483 14.9506 14.6171 13.1818 14.6171 10.9999C14.6171 8.81807 12.8483 7.04932 10.6664 7.04932C8.48457 7.04932 6.71582 8.81807 6.71582 10.9999C6.71582 13.1818 8.48457 14.9506 10.6664 14.9506Z'
        stroke='#F1CE00'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.87646 9.41969L12.2468 10.9999L9.87646 12.5802V9.41969Z'
        stroke='#F1CE00'
        strokeWidth='0.888889'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1486_1744'>
        <rect
          width='9.48148'
          height='9.48148'
          fill='white'
          transform='translate(5.92578 6.25925)'
        />
      </clipPath>
    </defs>
  </svg>
);
