import { generatePath, useNavigate, useParams } from "react-router-dom";

import { ROUTES } from "~/router";
import { getNextActivity } from "../utils/activities/getNextActivity";
import { getPreviousActivity } from "../utils/activities/getPreviousActivity";
import { useGetCourseCached } from "./queries/courses/useGetCourseCached";

export const useActivitiesNavigation = () => {
  const navigate = useNavigate();

  const {
    activityId: currentActivityId,
    chapterId: currentChapterId,
    moduleId: currentModuleId,
    courseId,
    type,
  } = useParams();

  const {
    cachedCourseQuery: { data: courseData },
  } = useGetCourseCached(courseId);

  const { data: course } = { ...courseData };

  const handleNext = () => {
    const next = getNextActivity(
      course,
      currentChapterId,
      currentModuleId,
      currentActivityId,
      type,
    );

    if (!next) return;

    const { chapterId, moduleId, activityId, activityType } = { ...next };

    if (!activityId) {
      navigate(generatePath(ROUTES.courseDetails, { id: courseId ?? "" }));
      return;
    }

    const redirectPath = generatePath(ROUTES.activity, {
      chapterId,
      moduleId,
      activityId,
      courseId: courseId ?? "",
      type: activityType,
    });

    navigate(redirectPath);
  };

  const handlePrevious = () => {
    const previous = getPreviousActivity(
      course,
      currentChapterId,
      currentModuleId,
      currentActivityId,
      type,
    );

    if (!previous) return;

    const { chapterId, moduleId, activityId, activityType } = { ...previous };

    if (!activityId) {
      navigate(generatePath(ROUTES.courseDetails, { id: courseId ?? "" }));
      return;
    }

    const redirectPath = generatePath(ROUTES.activity, {
      chapterId,
      moduleId,
      activityId,
      courseId: courseId ?? "",
      type: activityType,
    });

    navigate(redirectPath);
  };

  return { handleNext, handlePrevious };
};
