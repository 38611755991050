import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import type { z } from "zod";

import { BaseRadioButton, Form } from "~/shared";
import { useGetChoice } from "~/shared/hooks/queries/activities/useGetChoice";
import { choiceSchema } from "~/shared/schemas";
import HighlightedText from "../HighlightedText";
import { CorrectAnswerResultText } from "../ui/CorrectAnswerResultText";
import { IncorrectAnswerResultText } from "../ui/IncorrectAnswerResultText";
import { NextAndPreviousButtons } from "../ui/NextAndPreviousButtons";
import ChoiceOption from "./ChoiceOption";

type ChoiceFormValues = z.infer<typeof choiceSchema>;

export const ChoiceForm = () => {
  const { t } = useTranslation();
  const { activityId: currentActivityId } = useParams();

  const { data, isLoading } = useGetChoice(currentActivityId);

  const form = useForm<ChoiceFormValues>({
    resolver: zodResolver(choiceSchema),
  });

  if (!data || isLoading) return null;

  const { options, explanation } = data;

  const { handleSubmit, watch } = form;

  const saveAnswers: SubmitHandler<ChoiceFormValues> = (data) => {
    console.log(data);
  };

  const title = t(`activities.${data.title}`);
  const description = t(`activities.${data.description}`);

  const selectedOption = options?.find(
    (option) => option.id == `${watch("answer")}`,
  );

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit(saveAnswers)}
        className='flex h-full flex-col justify-between gap-4 bg-neutral-background p-8'
      >
        <div className='flex h-full flex-col gap-4 pb-12'>
          <h1 className='text-2xl font-semibold'>{title}</h1>

          <div className='flex h-full flex-col justify-between gap-4 rounded-xl bg-white p-7'>
            <div className='flex flex-col gap-8'>
              {!!description.length && <HighlightedText text={description} />}
              <div className='flex flex-col gap-4'>
                <BaseRadioButton control={form.control} name='answer'>
                  {options?.map((option) => (
                    <ChoiceOption
                      isSelected={selectedOption?.id === option.id}
                      key={option.id}
                      option={option}
                    />
                  ))}
                </BaseRadioButton>
              </div>
            </div>

            <div className='flex w-full flex-col gap-4'>
              {selectedOption &&
                (selectedOption?.isCorrect ? (
                  <CorrectAnswerResultText
                    text={t(`activities.${explanation}`)}
                  />
                ) : (
                  <IncorrectAnswerResultText />
                ))}
            </div>
          </div>
        </div>
        <NextAndPreviousButtons />
      </form>
    </Form>
  );
};
