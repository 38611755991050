import { useQuery } from "@tanstack/react-query";

import { getActivity } from "~/api/getActivity";

export const useGetActivityQuery = (activityId: string) => {
  const { data, isPending } = useQuery({
    queryKey: ["activity", activityId],
    queryFn: () => getActivity(activityId),
    enabled: !!activityId,
  });

  return { data: data?.data, isPending };
};
