import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ArrowIcon, KidneyIcon } from "~/shared";
import { ProgressBar } from "~/shared/components/utils/ProgressBar";
import { useGetModuleProgress } from "~/shared/hooks/queries/activities/useGetModuleProgress";
import type { Course } from "~/types/courses";
import { PathStepItem } from "./PathStepItem";

interface HeaderProps {
  activityPath: string[] | null;
  course: Course;
}

export const Header = ({ activityPath, course }: HeaderProps) => {
  const { t } = useTranslation();

  const { chapterId, moduleId } = useParams();

  const { data } = useGetModuleProgress(moduleId);

  const currentChapter = course.chapters.find((ch) => ch.id == chapterId);

  if (!currentChapter || !data) return null;

  const { completedActivities, totalActivities } = { ...data.progress };

  const progress = Math.round((completedActivities / totalActivities) * 100);

  const totalCompletedActivities = `${completedActivities} / ${totalActivities} ${t("Activities")}`;

  return (
    <div>
      <div className='relative flex flex-col gap-8 overflow-hidden bg-gray-50 px-14 py-8'>
        <div className='z-30 flex flex-row gap-2'>
          {activityPath?.map((value, index) => (
            <PathStepItem
              key={value}
              value={value}
              isLast={activityPath.length === index + 1}
            />
          ))}
        </div>
        <div className='z-10 flex flex-row items-center gap-6'>
          <ProgressBar
            progressClassName='rounded-full'
            className='rounded-full'
            progress={progress}
          />
          <div className='flex min-w-fit flex-row items-center gap-2'>
            <p className='text-gray-600'>{totalCompletedActivities}</p>
            <ArrowIcon className='text-primary-500' />
          </div>
        </div>
        <KidneyIcon className='absolute -right-12 -top-16 max-h-[16.5rem]' />
      </div>
    </div>
  );
};
