import React from "react";
import { useTranslation } from "react-i18next";

import { NormalValuesSVG } from "../../icons";
import {
  CloseButton,
  Content,
  Overlay,
  Root,
  Title,
} from "../../ui/Modal/Modal";

export const ImageModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Root open={isOpen} onOpenChange={onClose}>
      <Overlay />
      <Content className='flex w-1/3 flex-col items-center gap-10 p-5'>
        <div className='flex w-full items-center justify-between border-b border-gray-200 pb-2'>
          <Title className='font-bold'>
            {t("renalPhysiology.chapter3.module2.imageModalTitle")}
          </Title>
          <CloseButton />
        </div>
        <NormalValuesSVG />
      </Content>
    </Root>
  );
};
