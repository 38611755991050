import { useTranslation } from "react-i18next";

import { moduleDescriptionPoints } from "~/shared/utils/activities/getModuleDescription";
import { BulletPoint } from "../../icons";

export const ModuleDescription = ({ description }: { description: string }) => {
  const descriptionPoints = moduleDescriptionPoints[description];
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-4 px-9'>
      <div className='flex flex-col gap-4'>
        <h1 className='font-medium text-gray-600'>
          🎯 {t("ui.learningObjectives")}
        </h1>
        <div className='flex flex-col gap-2'>
          {descriptionPoints?.map(({ text, subPoints }) => (
            <div key={text} className='flex flex-col gap-2'>
              <div className='flex flex-row items-center gap-2'>
                <BulletPoint className='min-w-fit text-primary-200' />
                <p className='text-neutral-black'>
                  {t(`modules.${description}.${text}`)}
                </p>
              </div>
              {subPoints?.map((subPoint) => (
                <div
                  key={subPoint}
                  className='flex flex-row items-center gap-2 px-6'
                >
                  <BulletPoint className='min-w-fit text-primary-200' />
                  <p className='text-neutral-black'>
                    {t(`modules.${description}.${subPoint}`)}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
