import React from "react";
import { Link, useLocation } from "react-router-dom";

import { tw } from "~/shared";

interface SidebarItemProps {
  name: string;
  href: string;
  Icon: React.ElementType;
  isExpanded: boolean;
  onClick?: () => void;
}

export const SidebarItem = ({
  name,
  href,
  Icon,
  isExpanded,
  onClick,
}: SidebarItemProps) => {
  const location = useLocation();
  const current = location.pathname;

  return (
    <li>
      <Link
        to={href}
        className={tw(
          "group flex h-10 w-10 flex-row items-center justify-between gap-x-3 rounded-md p-3 text-sm leading-6 text-neutral-black",
          current === href && "border border-primary-100 bg-neutral-background",
          isExpanded && "h-auto w-auto px-3 py-2",
        )}
        onClick={onClick}
      >
        {isExpanded && name}
        <Icon className={tw("h-4 w-4", current === href && "text-pop-off")} />
      </Link>
    </li>
  );
};
