import { useParams } from "react-router-dom";

import { AmountSlider } from "./sliders/AmountSlider";
import { DoublePersonSlider } from "./sliders/DoublePersonSlider";
import { SliderOne } from "./sliders/SliderOne";
import { SliderTwo } from "./sliders/SliderTwo";

export const SLIDER_IDS: Record<string, JSX.Element> = {
  1: <SliderOne />,
  2: <SliderTwo />,
  3: <DoublePersonSlider />,
  4: <DoublePersonSlider />,
  5: <DoublePersonSlider />,
  6: <DoublePersonSlider />,
  7: <DoublePersonSlider />,
  14: <AmountSlider />,
};

export const SliderRenderer = () => {
  const { activityId } = useParams();

  if (activityId) {
    return SLIDER_IDS[activityId];
  }
  return null;
};
