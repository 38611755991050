import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { signUp } from "~/api/auth";
import { ROUTES } from "~/router";

export const useSignUpMutation = () => {
  const navigate = useNavigate();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: signUp,
    onSuccess: () => {
      navigate(ROUTES.login);
    },
  });

  return { mutate, isPending, isError, error };
};
