import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { ActivityLayout } from "~/layout/ActivityLayout";
import { DragAndDropArea } from "~/shared/components/Activities/dragAndDrop";
import { useDragAndDrop } from "~/shared/hooks/useDragAndDrop";
import Spinner from "../../ui/Spinner/Spinner";
import { dragAndDropConfigs } from "./dragAndDropConfigs";

export const DragAndDrop = () => {
  const { activityId } = useParams();
  const { t } = useTranslation();

  const {
    draggablePositions,
    activeId,
    draggableIdsWithError,
    dropzoneIdsWithError,
    isActivityCorrect,
    handleDragStart,
    handleDragEnd,
    areAnswersCorrect,
    findDraggingOption,
    activityData,
    isActivityIncorrect,
    resetDragAndDropState,
  } = useDragAndDrop();

  if (!activityData || !activityId) {
    return <Spinner />;
  }
  const dragAndDropData = dragAndDropConfigs[activityId];
  return (
    <ActivityLayout
      title={activityData?.title}
      successMessage={isActivityCorrect ? t(activityData.explanation) : ""}
      hasError={isActivityIncorrect}
      onCheckClick={areAnswersCorrect}
      isCheckDisabled={Object.keys(draggablePositions).every(
        (key) => draggablePositions[key] == null,
      )}
      hasAbsolutePositionExplanation={
        dragAndDropData.hasAbsolutePositionExplanation
      }
      resetActivityState={resetDragAndDropState}
    >
      <DragAndDropArea
        handleDragEnd={handleDragEnd}
        handleDragStart={handleDragStart}
        activityData={activityData}
        boxClassName={dragAndDropData?.dropzoneStyle}
        isActivityCorrect={isActivityCorrect}
        findDraggingOption={findDraggingOption}
        draggableIdsWithError={draggableIdsWithError}
        dropzoneIdsWithError={dropzoneIdsWithError}
        activeId={activeId}
        draggablePositions={draggablePositions}
        dropzonePositions={dragAndDropData?.dropzonePositions}
        dragImage={dragAndDropData?.image}
        type={dragAndDropData.type}
        dragIcons={dragAndDropData.dragIcons}
      />
    </ActivityLayout>
  );
};
