import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import type { z } from "zod";

import { ROUTES } from "~/router";
import { Button, Form, Text, useLoginMutation } from "~/shared";
import { loginFormSchema } from "../../schemas/auth";
import Spinner from "../ui/Spinner/Spinner";
import BaseInputFormItem from "../utils/InputFormItem";
import PasswordInputFormItem from "../utils/PasswordInputFormItem";

type LoginFormValuesType = z.infer<typeof loginFormSchema>;

const LoginForm = () => {
  const { t } = useTranslation();
  const form = useForm<LoginFormValuesType>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { mutate: loginMutation, isPending, error } = useLoginMutation();
  const onSubmit: SubmitHandler<LoginFormValuesType> = (values) => {
    loginMutation(values);
  };

  const { isDirty } = form.formState;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='flex flex-col gap-8'
      >
        <BaseInputFormItem
          control={form.control}
          name='email'
          label={t("login.email")}
          type='email'
          placeholder={t("login.email.placeholder")}
          hasError={!!error}
        />
        <div className='flex flex-col gap-4'>
          <PasswordInputFormItem
            name='password'
            control={form.control}
            hasError={!!error}
          />
          {error && <Text variant='error'>{error.message}</Text>}
          <Link
            to={ROUTES.forgotPassword}
            className='self-end border-b border-neutral-black text-sm font-bold'
          >
            {t("login.forgotPassword")}
          </Link>
        </div>
        <Button className='w-full' type='submit' disabled={!isDirty}>
          {isPending ? <Spinner /> : t("login.value")}
        </Button>
      </form>
    </Form>
  );
};

export default LoginForm;
