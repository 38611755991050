export const MultipleChoiceIcon = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      y='0.333328'
      width='21.3333'
      height='21.3333'
      rx='10.6667'
      fill='#FFEBF3'
    />
    <path
      d='M8.29639 7.44444H16.0001'
      stroke='#FF8DBE'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.29639 11H16.0001'
      stroke='#FF8DBE'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.29639 14.5556H16.0001'
      stroke='#FF8DBE'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.3335 7.44444H5.34016'
      stroke='#FF8DBE'
      strokeWidth='1.77778'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.3335 11H5.34016'
      stroke='#FF8DBE'
      strokeWidth='1.77778'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.3335 14.5556H5.34016'
      stroke='#FF8DBE'
      strokeWidth='1.77778'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
