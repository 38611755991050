import {
  DragAndDropIcon,
  MultipleChoiceIcon,
  VideoIcon,
} from "~/shared/components";
import { ACTIVITY_TYPES } from "~/types";

export const activityIcon = {
  [ACTIVITY_TYPES.choice]: <MultipleChoiceIcon />,
  [ACTIVITY_TYPES.slider]: <DragAndDropIcon />,
  [ACTIVITY_TYPES.dragAndDrop]: <DragAndDropIcon />,
  [ACTIVITY_TYPES.video]: <VideoIcon />,
  [ACTIVITY_TYPES.text]: <MultipleChoiceIcon />,
};
