import { useTranslation } from "react-i18next";

import { ChevronIcon } from "~/shared/components/icons";
import { tw } from "~/shared/utils";
import { activityIcon } from "~/shared/utils/activities/getActivityIcon";

interface PathStepItemProps {
  value: string;
  isLast?: boolean;
}

export const PathStepItem = ({ value, isLast }: PathStepItemProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-row items-center gap-2 text-gray-600'>
      {isLast && activityIcon[value.split(".").pop() ?? 0]}
      <p className={tw(isLast ? "text-neutral-black" : "underline")}>
        {t(value)}
      </p>
      {!isLast && <ChevronIcon className='size-2' />}
    </div>
  );
};
