import type { Chapter } from "~/types";
import { ModuleCard } from "../modules/ModuleCard";
import { ChapterCardHeader } from "./ChapterCardHeader";

interface ModuleCardProps {
  chapter: Chapter;
}

export const ChapterCard = ({ chapter }: ModuleCardProps) => {
  return (
    <div className='flex flex-col gap-4 rounded-3xl border border-primary-50 bg-white p-8'>
      <ChapterCardHeader chapter={chapter} />
      {chapter.modules.map((module) => (
        <ModuleCard key={module.id} module={module} chapterId={chapter.id} />
      ))}
    </div>
  );
};
