import type { UniqueIdentifier } from "@dnd-kit/core";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import { DragDropTypes } from "~/types/dragAndDrop";

export const Draggable = ({
  option,
  draggableId,
  className,
  dragType,
  dragIcons,
}: {
  option: string;
  draggableId: UniqueIdentifier;
  hasError?: boolean;
  className?: string;
  dragType?: number;
  dragIcons?: Record<string, JSX.Element>;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: draggableId,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };
  if (dragType === DragDropTypes.RIGHT_DRAGGABLES) {
    return (
      <div
        key={draggableId}
        style={style}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className={className}
      >
        {dragIcons?.[option]}
      </div>
    );
  }
  return (
    <button
      key={draggableId}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={className}
    >
      <p>{option}</p>
    </button>
  );
};
