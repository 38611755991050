import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { ROUTES } from "~/router";
import { ArrowIcon } from "~/shared";
import { activityIcon } from "~/shared/utils/activities/getActivityIcon";
import type { Activity } from "~/types";

interface ActivityCardProps {
  chapterId: string;
  moduleId: string;
  activity?: Activity;
}

export const ActivityCard = ({
  chapterId,
  moduleId,
  activity,
}: ActivityCardProps) => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();

  if (!activity || !courseId) return null;

  const redirectPath = generatePath(ROUTES.activity, {
    courseId,
    chapterId,
    moduleId,
    type: activity.type,
    activityId: activity.id,
  });

  return (
    <Link
      to={redirectPath}
      className='flex w-full flex-row items-center justify-between rounded-lg border border-primary-50 bg-neutral-white px-6 py-5'
    >
      <div className='flex flex-row items-center gap-2'>
        {activityIcon[activity.type]}
        <p className='font-medium text-neutral-black'>
          {t(`activities.types.${activity.type}`)}
        </p>
      </div>
      <ArrowIcon direction='right' className='text-primary-500' />
    </Link>
  );
};
