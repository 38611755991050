import React from "react";
import { useTranslation } from "react-i18next";

import { LeftValuesIcon } from "~/shared/components/icons/LeftValuesIcon";
import { RightValuesIcon } from "~/shared/components/icons/RightValuesIcon";

export const SliderInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='absolute bottom-0 left-0'>
        <LeftValuesIcon className='h-64' />
      </div>
      <div className='absolute -left-[4rem] bottom-[4.2rem]'>
        <p className='text-xxs text-gray-600'>{t("Intracellular")}</p>
        <p className='text-xxs text-gray-600'>{t("space")}</p>
      </div>
      <div className='absolute -left-[4rem] top-44'>
        <p className='text-xxs text-gray-600'>{t("Extracellular")}</p>
        <p className='text-xxs text-gray-600'>{t("space")}</p>
      </div>
      <div className='absolute bottom-[11.7rem] right-2'>
        <RightValuesIcon className='h-[70px]' />
      </div>
      <div className='absolute -right-[3.6rem] bottom-[14.1rem]'>
        <p className='text-xxs text-gray-600'>{t("Intravascular")}</p>
        <p className='text-xxs text-gray-600'>{t("space")}</p>
      </div>
      <div className='absolute -right-[4.3rem] bottom-[11.9rem]'>
        <p className='text-xxs text-gray-600'>{t("Interstitial")}</p>
        <p className='text-xxs text-gray-600'>{t("space (plasma)")}</p>
      </div>
    </>
  );
};
