import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAnswerActivity } from "~/shared/hooks/queries/activities/useAnswerActivity";
import { useActivitiesNavigation } from "~/shared/hooks/useActivitiesNavigation";
import { Button } from "../../ui";

interface NextAndPreviousButtonsProps {
  onCheckClick?: () => void;
  isCheckDisabled?: boolean;
  resetActivityState?: () => void;
}

export const NextAndPreviousButtons = ({
  onCheckClick,
  isCheckDisabled,
  resetActivityState,
}: NextAndPreviousButtonsProps) => {
  const { t } = useTranslation();

  const { type: activityType, activityId } = useParams();

  const { answerActivityMutation } = useAnswerActivity();

  const { handleNext, handlePrevious } = useActivitiesNavigation();

  if (!activityId || !activityType) return null;

  const onNext = () => {
    handleNext();
    answerActivityMutation({ activityId, activityType });
    if (resetActivityState) {
      resetActivityState();
    }
  };

  return (
    <div className='flex w-full flex-row justify-between'>
      <Button onClick={handlePrevious} variant='underline' type='button'>
        {t("ui.previous")}
      </Button>
      <div className='flex gap-4'>
        {onCheckClick && (
          <Button disabled={isCheckDisabled} onClick={onCheckClick}>
            Check answers
          </Button>
        )}
        <Button
          variant='outline'
          className='w-52'
          type='submit'
          onClick={onNext}
        >
          {t("ui.next")}
        </Button>
      </div>
    </div>
  );
};
