export const DragAndDropIcon = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      y='0.333328'
      width='21.3333'
      height='21.3333'
      rx='10.6667'
      fill='#F3EDFD'
    />
    <g clipPath='url(#clip0_1486_2049)'>
      <path
        d='M6.51899 9.22221L4.74121 11L6.51899 12.7778'
        stroke='#A579EC'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.88867 6.85178L10.6664 5.07401L12.4442 6.85178'
        stroke='#A579EC'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.4442 15.1482L10.6664 16.926L8.88867 15.1482'
        stroke='#A579EC'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.8145 9.22221L16.5922 11L14.8145 12.7778'
        stroke='#A579EC'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.74121 11H16.5931'
        stroke='#A579EC'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.667 5.07401V16.9259'
        stroke='#A579EC'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1486_2049'>
        <rect
          width='14.2222'
          height='14.2222'
          fill='white'
          transform='translate(3.55566 3.88889)'
        />
      </clipPath>
    </defs>
  </svg>
);
