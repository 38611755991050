import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ActivityLayout } from "~/layout/ActivityLayout";
import { useGetSliderActivityQuery } from "~/shared";
import Spinner from "~/shared/components/ui/Spinner/Spinner";
import { SliderContent } from "./SliderContent";

export const SliderTwo: React.FC = () => {
  const [waterHeight, setWaterHeight] = useState(0);
  const [hasSuccessMessage, setHasSuccessMessage] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);

  const { activityId } = useParams<{ activityId: string }>();
  const { data: activityData, isPending } = useGetSliderActivityQuery(
    activityId ?? "",
  );

  useEffect(() => {
    const firstSlider = activityData?.sliders[0];
    if (firstSlider?.initial) setWaterHeight(Number(firstSlider.initial));
  }, [activityData]);

  const { t } = useTranslation();

  if (isPending) return <Spinner />;

  if (!activityData) return <p>No activity data.</p>;

  const { sliders, title, description, explanation } = activityData;

  const [waterSlider] = sliders;

  const waterSliderMinAnswer = Number(waterSlider.answer_minimum);
  const waterSliderMaxAnswer = Number(waterSlider.answer_maximum);

  const validateAnswer = () => {
    const isCorrect =
      waterHeight >= waterSliderMinAnswer &&
      waterHeight <= waterSliderMaxAnswer;
    setHasSuccessMessage(isCorrect);
    setHasErrorMessage(!isCorrect);
  };

  return (
    <ActivityLayout
      title={t(`activities.${title}`)}
      successMessage={hasSuccessMessage ? t(`activities.${explanation}`) : ""}
      hasError={hasErrorMessage}
      onCheckClick={validateAnswer}
      isCheckDisabled={false}
      hasAbsolutePositionExplanation={true}
    >
      <SliderContent
        description={description}
        waterSlider={waterSlider}
        waterHeight={waterHeight}
        setWaterHeight={setWaterHeight}
      />
    </ActivityLayout>
  );
};
